import logo from './logo.svg';
import './App.css';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import CouponId from './pages/CouponId';
import Logout from './pages/Logout';
import {Route , Link, Switch } from 'react-router-dom';
import React, {Component} from 'react';

function App() { 
  return (
    <React.Fragment>
      <Switch>
    <Route exact path="/" component={Login} />
     <Route exact path="/login" component={Login} />
<Route exact path="/dashboard" component={Dashboard} />
<Route exact path="/couponid" component={CouponId} />
<Route exact path="/logout" component={Logout} />

       </Switch>
  </React.Fragment> 
  );
}

export default App;
