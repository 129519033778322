import React, { Component, useEffect } from "react";
import LoginHeader from "./Components/LoginHeader";
import Footer from "./Components/Footer";
import axios from "axios";
import { Redirect, withRouter } from "react-router-dom";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faUndo } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert";
import Swal2 from "sweetalert2";
import * as XLSX from "xlsx";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
let fs;
if (typeof window === "undefined") {
  fs = require("fs");
}
require("dotenv").config();
class CouponId extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.addAgreementInfo = this.addAgreementInfo.bind(this);
    this.addTextToPage = this.addTextToPage.bind(this);
    this.inputRefs = {};
  }

  componentDidMount() {
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");

    const token = queryParams.get("token");

    if (!token || token === "null") {
      Swal({
        text: "Token is required.",
        icon: "error",
        button: "OK",
      });
      return;
    }

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/authenticationAPI/GetQuoteDetailsByQuoteId?quote_id=${id}`
      )
      .then((response) => {
        console.log("helllo");
        console.log(response.data[0]["CouponToken"]);
        console.log(token);

        if (token != response.data[0]["CouponToken"]) {
          Swal({
            text: "token not match.",
            icon: "error",
            button: "OK",
          });

          return false;
        }

        var quoteid = response.data[0]["ApplicationID"];
        var preApprovedStatus = response.data[0]["PreApprovedStatus"];
        var cu_referral = response.data[0]["CuReferral"];
        var branch = response.data[0]["Branch"];

        var name = response.data[0]["FirstNames"];
        var email = response.data[0]["EmailAddresses"];
        var pone = response.data[0]["Phone"];

        var amazonStatus = 1;
        var amazonCouponStatus = 1;
        var CuCouponFileName = response.data[0]["CuCouponFileName"];

        this.addAgreementInfo(
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          parseInt(amazonStatus),
          quoteid,
          name,
          email,
          pone,
          cu_referral,
          branch,
          parseInt(preApprovedStatus),
          CuCouponFileName
        );
      });
  }

  addTextToPage = async (page, texts, font) => {
    for (const { text, x, y, size } of texts) {
      page.drawText(text, {
        x: x,
        y: y,
        size: size,
        font: font,
        color: rgb(0, 0, 0),
      });
    }
  };
  addAgreementInfo = async (
    dealerName,
    terms,
    date,
    year,
    make,
    model,
    salesRep,
    signConfirm,
    receiveAmazonStatus,
    credit,
    customerName,
    customeEmail,
    cell,
    branch,
    cuReferral,
    preApprovedStatus,
    CuCouponFileName
  ) => {
    let filePath;
    if (
      CuCouponFileName &&
      CuCouponFileName != "undefined" &&
      CuCouponFileName !== undefined &&
      CuCouponFileName !== "" &&
      CuCouponFileName !== "null"
    ) {
      var filename = CuCouponFileName;
      const bucketName = "pifapp";
      const region = "us-east-2";
      const baseUrl = `https://${bucketName}.s3.${region}.amazonaws.com/coupons/`;
      const fileUrl = `${baseUrl}${filename}.pdf`;

      filePath = fileUrl;
    } else {
      filePath = window.location.origin + "/image.pdf";
    }

    const response = await fetch(filePath, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        "Access-Control-Allow-Origin": "*", // This header must be set on the server-side
      },
    });
    const arrayBuffer = await response.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const page = pdfDoc.getPages()[0];
    console.log(receiveAmazonStatus);
    console.log(preApprovedStatus);
    const borrowerInfo = [
      { text: String(dealerName), x: 220, y: 150, size: 8 },
      { text: String(terms), x: 320, y: 170, size: 8 },
      { text: String(date), x: 510, y: 152, size: 8 },
      { text: String(year), x: 210, y: 130, size: 8 },
      { text: String(make), x: 290, y: 130, size: 8 },
      { text: String(model), x: 340, y: 130, size: 8 },
      { text: String(salesRep), x: 280, y: 115, size: 8 },
      { text: String(signConfirm), x: 420, y: 115, size: 8 },
      receiveAmazonStatus == 0
        ? { text: String("."), x: 383, y: 85, size: 80 }
        : { text: String("."), x: 423, y: 85, size: 80 },
      preApprovedStatus == 1
        ? { text: String("."), x: 181, y: 29, size: 50 }
        : { text: String("."), x: 0, y: 0, size: 0 },
      { text: String(credit), x: 520, y: 70, size: 8 },
      { text: String(customerName), x: 260, y: 70, size: 8 },
      { text: String(customeEmail), x: 360, y: 50, size: 8 },
      { text: String(cell), x: 240, y: 50, size: 8 },
      { text: String(branch), x: 340, y: 30, size: 8 },
      { text: String(cuReferral), x: 480, y: 30, size: 8 },
    ];
    await this.addTextToPage(page, borrowerInfo, font);
    const pdfBytes = await pdfDoc.save();
    const blobcoupon = new Blob([pdfBytes], { type: "application/pdf" });
    const urlcoupon = URL.createObjectURL(blobcoupon);

    // Open the downloaded PDF in a new tab
    window.open(urlcoupon, "_blank");
    const a = document.createElement("a");
    a.href = urlcoupon;
    a.download = customerName + "_" + credit + ".pdf";
    a.click();

    // setTimeout(function() {
    // window.location.reload();
    // },2000);
  };

  render() {
    return (
      <>
        <div></div>

        <style>{`
                @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
            `}</style>
      </>
    );
  }
}
export default CouponId;
