import React, { Component, useEffect } from "react";
import LoginHeader from "./Components/LoginHeader";
import Footer from "./Components/Footer";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert";
import jwtEncode from "jwt-encode";
import { jwtDecode } from "jwt-decode";
require("dotenv").config();
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      redirect: false,
      loading: false,
      isCheckingToken: true,
    };
  }
  resetPassword = (e) => {
    e.preventDefault();
    if (this.state.forgotemail == "") {
      alert("Please enter email.");
      return false;
    }

    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(this.state.forgotemail) === false) {
      alert("Please enter correct email.");
      return false;
    }

    const clientData = { email: this.state.forgotemail };
    axios
      .post(
        process.env.REACT_APP_BASE_URL + `/authenticationAPI/forgot-password`,
        clientData
      )
      .then((res) => {
        if (res.data.Status) {
          alert(res.data.Message);
        } else alert(res.data.Message);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  handleChange = (event) => {
    const input = event.target;
    const value = input.type === "checkbox" ? input.checked : "";
    console.log(input.checked);
    if (input.checked === false) {
      // localStorage.setItem('repassword','');
      // localStorage.setItem('reemail','');
      // localStorage.setItem('rememberMe','');
      // this.setState({ 'rememberMe': '' });
    } else {
      // this.setState({ 'rememberMe': value });
    }
  };

  emailValue(e) {
    // localStorage.setItem('reemail','');
    // localStorage.setItem('rememberMe','');
    // this.setState({ 'rememberMe': '' });
    this.setState({ email: e.target.value });
  }

  passwordValue(e) {
    // localStorage.setItem('repassword','');
    //  localStorage.setItem('rememberMe','');
    //  this.setState({ 'rememberMe': '' });
    this.setState({ password: e.target.value });
  }

  userlogin = (e) => {
    e.preventDefault();
    if (this.state.email == "") {
      Swal({
        text: "Please enter email.",
        icon: "error",
        button: "OK",
      });
      return false;
    }
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(this.state.email) === false) {
      Swal({
        text: "Please enter correct email.",
        icon: "error",
        button: "OK",
      });
      return false;
    }

    if (this.state.password == "") {
      Swal({
        text: "Please enter password.",
        icon: "error",
        button: "OK",
      });
      return false;
    }

    const clientData = {
      email: this.state.email,
      password: this.state.password,
      token: "",
    };
    var token = jwtEncode(
      {
        email: this.state.email,
        exp: Math.floor(Date.now() / 1000) + 3 * 60 * 60,
      },
      "test"
    );
    this.setState({ loading: true });
    setTimeout(() => {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/authenticationAPI/getlogo`, {
          params: { user_id: 1 },
        })
        .then((response) => {
          // Handle the successful response
          console.log("Logo data:", response.data);
          localStorage.setItem("PifLogo", response.data[0].Logo);
          // You can update the state or perform other actions here
        });
      console.log("after login auth token");
      axios
        .post(
          process.env.REACT_APP_BASE_URL + `/authenticationAPI/Login`,
          clientData
        )
        .then((res) => {
          this.setState({ loading: false });
          if (res.data.Status) {
            console.log("normal login");

            if (res.data.CustomerType == "None") {
              localStorage.setItem("UrlCustomerType", "None");
            } else {
              localStorage.setItem("UrlCustomerType", null);
            }
            console.log(localStorage.getItem("UrlCustomerType"));
            localStorage.setItem("customer_id", res.data.MemberID);
            localStorage.setItem("LoginName", res.data.LoginName);
            localStorage.setItem("LoginEmail", this.state.email);
            localStorage.setItem("Logo", res.data.Logo);
            localStorage.setItem("CustomerType", res.data.CustomerType);
            localStorage.setItem("UserID", res.data.UserID);
            localStorage.setItem("DealerId", res.data.UserID);
            localStorage.setItem(
              "CuDefaultPercentage",
              res.data.CuDefaultPercentage
            );
            localStorage.setItem("CuPdfFileName", res.data.CuCouponFileName);
            localStorage.setItem("CuDefaultBranch", res.data.CuDefaultBranch);
            localStorage.setItem("CompanyName", res.data.CompanyName);
            localStorage.setItem("BankAddress", res.data.BankAddress);
            localStorage.setItem("BankAddress2", res.data.BankAddress2);
            localStorage.setItem("BankState", res.data.BankState);
            localStorage.setItem("BankZip", res.data.BankZip);
            localStorage.setItem("BankCity", res.data.BankCity);
            localStorage.setItem(
              "TempPasswordStatus",
              res.data.TempPasswordStatus
            );
            console.log(res.data.auth_token);
            localStorage.setItem("auth_token", res.data.auth_token);
            var tokenData = {
              token: token,
              user_member_id: res.data.MemberID,
              auth_token: localStorage.getItem("auth_token"),
            };
            axios
              .post(
                process.env.REACT_APP_BASE_URL +
                  `/authenticationAPI/UpdateToken`,
                tokenData
              )
              .then((res1) => {
                if (res1.data.status) {
                  console.log("Token updated successfully.");
                }
              })
              .catch((error) => {
                console.error("Error updating token:", error);
              });
            localStorage.setItem("token", token);
            this.setState({ redirect: true });
          } else {
            Swal({
              text: res.data.Message,
              icon: "error",
              button: "OK",
            });
          }
        });
    }, 2000);
  };

  //   componentDidMount()
  //   {
  //     const params = new URLSearchParams(window.location.search);
  //     var tokenFromUrl = params.get("token");
  //     var UrlCustomerType = params.get("customer_type");

  // if (UrlCustomerType !== null  && tokenFromUrl)
  // {
  //   localStorage.setItem("UrlCustomerType", UrlCustomerType);
  // }
  //                console.log("hello1");
  //                 const loginName = localStorage.getItem("LoginEmail");

  //                 if (tokenFromUrl)
  //                 {
  //                  const clientData = { email: "", password: "", token: tokenFromUrl };
  //                   axios.get(`${process.env.REACT_APP_BASE_URL}/authenticationAPI/getlogo`, {
  //                     params: { user_id: 1 }
  //                   })
  //                   .then(response =>
  //                   {
  //                     localStorage.setItem("PifLogo",response.data[0].Logo);
  //                   })

  //                   const decoded = jwtDecode(tokenFromUrl);
  //                   if (decoded.exp < Math.floor(Date.now() / 1000))
  //                   {
  //                             Swal({
  //                   text: "Your session has expired. Please log in again",
  //                   icon: "success",
  //                   button: "OK",
  //                 }).then(() => {
  //                   const url = new URL(window.location.href);
  //                   const paramName = "token";
  //                   url.searchParams.delete(paramName);
  //                   window.location.href = url.toString();
  //                 });
  //                this.setState({ redirect: false });
  //                             return false;
  //                   }

  //                   axios
  //                     .post(
  //                       process.env.REACT_APP_BASE_URL + `/authenticationAPI/Login`,
  //                       clientData
  //                     )
  //                     .then((res) => {
  //                       if (res.data.Status)
  //                       {
  //                         localStorage.setItem("customer_id", res.data.MemberID);
  //                         localStorage.setItem("LoginName", res.data.LoginName);
  //                         localStorage.setItem("LoginEmail", decoded.email);
  //                         localStorage.setItem("Logo", res.data.Logo);
  //                         localStorage.setItem("UserID", res.data.UserID);
  //                         localStorage.setItem("CustomerType", res.data.CustomerType);
  //                         localStorage.setItem("token", res.data.token);
  //                         localStorage.setItem("auth_token", res.data.auth_token);
  //                         localStorage.setItem(
  //                           "CuDefaultPercentage",
  //                           res.data.CuDefaultPercentage
  //                         );
  //                         localStorage.setItem("CuPdfFileName", res.data.CuCouponFileName);
  //                         localStorage.setItem("CuDefaultBranch", res.data.CuDefaultBranch);
  //                         localStorage.setItem("CompanyName", res.data.CompanyName);
  //                         localStorage.setItem("DealerId", res.data.UserID);
  //                         localStorage.setItem("BankAddress", res.data.BankAddress);
  //                         localStorage.setItem("BankAddress2", res.data.BankAddress2);
  //                         localStorage.setItem("BankState", res.data.BankState);
  //                         localStorage.setItem("BankZip", res.data.BankZip);
  //                         localStorage.setItem("BankCity", res.data.BankCity);
  //                         localStorage.setItem(
  //                           "TempPasswordStatus",
  //                           res.data.TempPasswordStatus
  //                         );
  //                      this.setState({ redirect: true });

  //                       }
  //                       else{
  //                         Swal({
  //                           text: res.data.Message,
  //                           icon: "error",
  //                           button: "OK",
  //                         });
  // }
  //  this.setState({ redirect: false });

  //                     }).catch(() => {
  //         this.setState({ redirect: false });
  //       });

  //                 }

  //  else {

  //    this.setState({ redirect: false });
  //   }

  //   }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get("token");
    const UrlCustomerType = params.get("customer_type");

    if (UrlCustomerType !== null && tokenFromUrl) {
      localStorage.setItem("UrlCustomerType", UrlCustomerType);
    }
    const loginName = localStorage.getItem("LoginEmail");

    if (tokenFromUrl) {
      // Decode the token and check expiration
      const decoded = jwtDecode(tokenFromUrl);
      if (decoded.exp < Math.floor(Date.now() / 1000)) {
        Swal({
          text: "Your session has expired. Please log in again",
          icon: "error",
          button: "OK",
        }).then(() => {
          const url = new URL(window.location.href);
          url.searchParams.delete("token");
          window.location.href = url.toString();
        });
        this.props.history.push("/login");
        this.setState({ IsCheckingToken: false });
        return;
      }

      const clientData = { email: "", password: "", token: tokenFromUrl };
      this.setState({ loading: true });
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/authenticationAPI/getlogo`, {
          params: { user_id: 1 },
        })
        .then((response) => {
          localStorage.setItem("PifLogo", response.data[0]?.Logo);
        })
        .catch(() => {
          console.error("Failed to fetch the logo");
        });

      // Attempt login
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/authenticationAPI/Login`,
          clientData
        )
        .then((res) => {
          this.setState({ loading: false });
          if (res.data.Status) {
            // Set user data in localStorage
            localStorage.setItem("customer_id", res.data.MemberID);
            localStorage.setItem("LoginName", res.data.LoginName);
            localStorage.setItem("LoginEmail", decoded.email);
            localStorage.setItem("Logo", res.data.Logo);
            localStorage.setItem("UserID", res.data.UserID);
            localStorage.setItem("CustomerType", res.data.CustomerType);
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("auth_token", res.data.auth_token);
            localStorage.setItem(
              "CuDefaultPercentage",
              res.data.CuDefaultPercentage
            );
            localStorage.setItem("CuPdfFileName", res.data.CuCouponFileName);
            localStorage.setItem("CuDefaultBranch", res.data.CuDefaultBranch);
            localStorage.setItem("CompanyName", res.data.CompanyName);
            localStorage.setItem("DealerId", res.data.UserID);
            localStorage.setItem("BankAddress", res.data.BankAddress);
            localStorage.setItem("BankAddress2", res.data.BankAddress2);
            localStorage.setItem("BankState", res.data.BankState);
            localStorage.setItem("BankZip", res.data.BankZip);
            localStorage.setItem("BankCity", res.data.BankCity);
            localStorage.setItem(
              "TempPasswordStatus",
              res.data.TempPasswordStatus
            );

            // Set redirect state to true after successful login
            this.props.history.push("/dashboard");
          } else {
            Swal({
              text: res.data.Message,
              icon: "error",
              button: "OK",
            });

            this.props.history.push("/login");
            this.setState({ IsCheckingToken: false });
          }
        })
        .catch(() => {
          console.error("Login request failed");
          this.props.history.push("/login");
          this.setState({ IsCheckingToken: false });
        });
    } else {
      this.props.history.push("/login");
      this.setState({ IsCheckingToken: false });
    }
  }

  render() {
    const { t } = this.props;
    if (this.state.redirect) return <Redirect to="/dashboard" />;
    return (
      <div class="main-wrapper">
        {this.state.loading && (
          <>
            <div
              class=""
              style={{
                color: "black",
                position: "fixed",
                top: "15%",
                left: "0",
                fontSize: "22px",
                width: "100%",
                height: "100%",
                zIndex: "9999",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Loading...
            </div>

            <div
              className="loader-overlay"
              style={{
                position: "fixed",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: "999",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "20px",
                fontWeight: "500",
              }}
            >
              <div className="loader-overlay">
                <div class="spinner-square">
                  <div class="square-1 square"></div>
                  <div class="square-2 square"></div>
                  <div class="square-3 square"></div>
                </div>
              </div>
            </div>
          </>
        )}

        <LoginHeader />
        {/*<div style={{textAlign:"center",color:"white", fontSize: "20px", fontWeight: "600", paddingTop: "25px", background: "rgb(0, 47, 108)"}}>
   <h1 style={{color: "white"}}>Pay IT Forward - PIF</h1><hr/>
   </div>*/}

        <div className="content pb-0">
          <div className="">
            <div className="card border-0 mb-0">
              <div className="row border-bottom"></div>
            </div>
          </div>

          <div className="container-fluid " style={{ background: "#cccccc38" }}>
            <div className="row">
              <div className="col-md-12">
                <div className="account-content">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-md-6 col-lg-6 login-right login-right bg-white mt-5 mb-5">
                      <div className="login-header">
                        <h3
                          className="login-title color-cus1"
                          style={{ textAlign: "center" }}
                        >
                          <span className="font-weight-bold"> Login</span>
                        </h3>
                      </div>
                      <form
                        action=""
                        method="POST"
                        style={{ width: "80%", margin: "0 auto" }}
                      >
                        <label className="focus-label">Email</label>
                        <div className="form-group form-focus focused">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            value={this.state.email}
                            onChange={(e) => this.emailValue(e)}
                          />
                        </div>
                        <label className="focus-label">Password</label>

                        <div className="form-group form-focus focused">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            value={this.state.password}
                            onChange={(e) => this.passwordValue(e)}
                          />
                        </div>

                        {/*<div className="text-left rem-main-div">
                    <a className="btn modal-trigger text-left w-100 pad-0" >
                    <span>
                    <input type="checkbox" checked={this.state.rememberMe}  onChange={this.handleChange}  id="remember" className="rem-me-input" /> 
                      <label for="remember-me" className="color-cus-gray">Remember me</label>
                     </span>
                                        </a>

                  </div>

                  <div className="text-right for-main-div">

                    <a className="btn modal-trigger pad-0" data-target="modal1">
                      <span> 
                        <label for="remember-me"  className="for-text fp font-weight-bold">Forgot Password ?</label>
                      </span>
                    </a>
                  </div>*/}
                        <button
                          style={{
                            boxShadow: "3px 4px 4px 0 rgb(0 47 108 / 40%)",
                            background: "#002f6c",
                            border: "1px solid #002f6c",
                            color: "white",
                          }}
                          className="btn btn-block btn-lg login-btn"
                          type="submit"
                          onClick={this.userlogin}
                        >
                          Login
                        </button>

                        {/* Conditional rendering of loading indicator */}
                      </form>
                      <div id="modal1" className="modal">
                        <div className="modal-content">
                          <a
                            href="#!"
                            className=" text-right modal-action modal-close waves-effect waves-green btn-flat"
                          >
                            Close
                          </a>
                          <h4 className="text-muted text-center">
                            Forgot Password
                          </h4>

                          <p>Email*</p>
                          <form>
                            <div className="form-group form-focus focused">
                              <input
                                required
                                type="email"
                                className="form-control floating"
                                onChange={(e) =>
                                  this.setState({ forgotemail: e.target.value })
                                }
                              />
                              <label className="focus-label">Email</label>
                            </div>
                            <button
                              className="btn btn-primary btn-block "
                              type="button"
                              onClick={this.resetPassword}
                            >
                              Submit
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
